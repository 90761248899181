// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-aktuell-js": () => import("./../../../src/pages/aktuell.js" /* webpackChunkName: "component---src-pages-aktuell-js" */),
  "component---src-pages-ausstellung-js": () => import("./../../../src/pages/ausstellung.js" /* webpackChunkName: "component---src-pages-ausstellung-js" */),
  "component---src-pages-curriculum-js": () => import("./../../../src/pages/curriculum.js" /* webpackChunkName: "component---src-pages-curriculum-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projekt-js": () => import("./../../../src/pages/projekt.js" /* webpackChunkName: "component---src-pages-projekt-js" */),
  "component---src-pages-publikation-js": () => import("./../../../src/pages/publikation.js" /* webpackChunkName: "component---src-pages-publikation-js" */),
  "component---src-pages-werk-js": () => import("./../../../src/pages/werk.js" /* webpackChunkName: "component---src-pages-werk-js" */),
  "component---src-templates-creation-js": () => import("./../../../src/templates/creation.js" /* webpackChunkName: "component---src-templates-creation-js" */),
  "component---src-templates-creation-type-js": () => import("./../../../src/templates/creationType.js" /* webpackChunkName: "component---src-templates-creation-type-js" */),
  "component---src-templates-exhibition-js": () => import("./../../../src/templates/exhibition.js" /* webpackChunkName: "component---src-templates-exhibition-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-publication-js": () => import("./../../../src/templates/publication.js" /* webpackChunkName: "component---src-templates-publication-js" */)
}

